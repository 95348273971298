import { faAnalytics, faBikingMountain, faBlog, faBooks, faBurgerSoda, faBusinessTime, faCameraMovie, faCameraRetro, faCars, faChalkboardTeacher, faChartLine, faDragon, faDumbbell, faFemale, faFlask, faFlowerTulip, faGem, faHandHoldingSeedling, faHashtag, faHeadSideBrain, faKissWinkHeart, faLandmark, faLaptop, faLightbulbOn, faLips, faLoveseat, faMegaphone, faMusic, faPalette, faPaw, faPeace, faPencilAlt, faPeopleCarry, faPhoneLaptop, faPlaneDeparture, faPray, faPrescriptionBottleAlt, faRecycle, faRingsWedding, faSackDollar, faSpa, faTreeChristmas, faTrees, faTshirt, faTypewriter, faUniversity, faUserChart, faUserGraduate, faUserHardHat, faUserMdChat, faUsers, faVest, faWatchFitness } from "@fortawesome/pro-light-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

const renderIcon = (id) => {
  switch(id) {
    case "paw": return <FontAwesomeIcon icon= {faPaw}></FontAwesomeIcon>
    case "palette": return <FontAwesomeIcon icon= {faPalette}></FontAwesomeIcon>
    case "cars": return <FontAwesomeIcon icon= {faCars}></FontAwesomeIcon>
    case "lips": return <FontAwesomeIcon icon= {faLips}></FontAwesomeIcon>
    case "laptop": return <FontAwesomeIcon icon= {faLaptop}></FontAwesomeIcon>
    case "books": return <FontAwesomeIcon icon= {faBooks}></FontAwesomeIcon>
    case "megaphone": return <FontAwesomeIcon icon= {faMegaphone}></FontAwesomeIcon>
    case "female": return <FontAwesomeIcon icon= {faFemale}></FontAwesomeIcon>
    case "business-time": return <FontAwesomeIcon icon= {faBusinessTime}></FontAwesomeIcon>
    case "user-hard-hat": return <FontAwesomeIcon icon= {faUserHardHat}></FontAwesomeIcon>
    case "chalkboard-teacher": return <FontAwesomeIcon icon= {faChalkboardTeacher}></FontAwesomeIcon>
    case "typewriter": return <FontAwesomeIcon icon= {faTypewriter}></FontAwesomeIcon>
    case "university": return <FontAwesomeIcon icon= {faUniversity}></FontAwesomeIcon>
    case "camera-movie": return <FontAwesomeIcon icon= {faCameraMovie}></FontAwesomeIcon>
    case "user-chart": return <FontAwesomeIcon icon= {faUserChart}></FontAwesomeIcon>
    case "users": return <FontAwesomeIcon icon= {faUsers}></FontAwesomeIcon>
    case "dragon": return <FontAwesomeIcon icon= {faDragon}></FontAwesomeIcon>
    case "tshirt": return <FontAwesomeIcon icon= {faTshirt}></FontAwesomeIcon>
    case "sack-dollar": return <FontAwesomeIcon icon= {faSackDollar}></FontAwesomeIcon>
    case "burger-soda": return <FontAwesomeIcon icon= {faBurgerSoda}></FontAwesomeIcon>
    case "dumbbell": return <FontAwesomeIcon icon= {faDumbbell}></FontAwesomeIcon>
    case "landmark": return <FontAwesomeIcon icon= {faLandmark}></FontAwesomeIcon>
    case "tree-christmas": return <FontAwesomeIcon icon= {faTreeChristmas}></FontAwesomeIcon>
    case "hand-holding-seedling": return <FontAwesomeIcon icon= {faHandHoldingSeedling}></FontAwesomeIcon>
    case "loveseat": return <FontAwesomeIcon icon= {faLoveseat}></FontAwesomeIcon>
    case "gem": return <FontAwesomeIcon icon= {faGem}></FontAwesomeIcon>
    case "people-carry": return <FontAwesomeIcon icon= {faPeopleCarry}></FontAwesomeIcon>
    case "biking-mountain": return <FontAwesomeIcon icon= {faBikingMountain}></FontAwesomeIcon>
    case "analytics": return <FontAwesomeIcon icon= {faAnalytics}></FontAwesomeIcon>
    case "music": return <FontAwesomeIcon icon= {faMusic}></FontAwesomeIcon>
    case "trees": return <FontAwesomeIcon icon= {faTrees}></FontAwesomeIcon>
    case "camera-retro": return <FontAwesomeIcon icon= {faCameraRetro}></FontAwesomeIcon>
    case "user-chart": return <FontAwesomeIcon icon= {faUserChart}></FontAwesomeIcon>
    case "head-side-brain": return <FontAwesomeIcon icon= {faHeadSideBrain}></FontAwesomeIcon>
    case "kiss-wink-heart": return <FontAwesomeIcon icon= {faKissWinkHeart}></FontAwesomeIcon>
    case "pray": return <FontAwesomeIcon icon= {faPray}></FontAwesomeIcon>
    case "flask": return <FontAwesomeIcon icon= {faFlask}></FontAwesomeIcon>
    case "flower-tulip": return <FontAwesomeIcon icon= {faFlowerTulip}></FontAwesomeIcon>
    case "prescription-bottle-alt": return <FontAwesomeIcon icon= {faPrescriptionBottleAlt}></FontAwesomeIcon>
    case "hashtag": return <FontAwesomeIcon icon= {faHashtag}></FontAwesomeIcon>
    case "peace": return <FontAwesomeIcon icon= {faPeace}></FontAwesomeIcon>
    case "vest": return <FontAwesomeIcon icon= {faVest}></FontAwesomeIcon>
    case "recycle": return <FontAwesomeIcon icon= {faRecycle}></FontAwesomeIcon>
    case "phone-laptop": return <FontAwesomeIcon icon= {faPhoneLaptop}></FontAwesomeIcon>
    case "plane-departure": return <FontAwesomeIcon icon= {faPlaneDeparture}></FontAwesomeIcon>
    case "rings-wedding": return <FontAwesomeIcon icon= {faRingsWedding}></FontAwesomeIcon>
    case "spa": return <FontAwesomeIcon icon= {faSpa}></FontAwesomeIcon>
    case "pencil-alt": return <FontAwesomeIcon icon= {faPencilAlt}></FontAwesomeIcon>
    case "watch-fitness": return <FontAwesomeIcon icon= {faWatchFitness}></FontAwesomeIcon>
    case "lightbulb-on": return <FontAwesomeIcon icon= {faLightbulbOn}></FontAwesomeIcon>
    default: return null
  }
}

const TemplateCategoryIcon = ({ className, id }) => {
  return (
    <div className={ className || '' } style={{color: "#7371FC", fontSize: "36px"}}>{ renderIcon(id) }</div>
  )
}

export default TemplateCategoryIcon